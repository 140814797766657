.login-main {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/bg_lines.png");
  }
  
  .data {
    position: absolute;
    top: 8%;
    width: 70%;
    height: 10%;
    color: #fff;
    left: 16%;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
  }
  
  .login-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #263448;
    font-style: normal;
    text-align: center;
    letter-spacing: 0.04em;
    color: #ffffff;
    opacity: 0.84;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.06);
    border-radius: 0px 50px 50px 0px;
  }
  
  .login-signin {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    letter-spacing: 0.04em;
  
    color: rgba(255, 255, 255, 0.7);
  
    opacity: 0.9;
  }
  
  .login-input {
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 3px rgba(34, 30, 30, 0.28),
      inset 0px 3px 3px rgba(255, 253, 253, 0.25);
    border-radius: 12px;
    display: block;
    margin: 2%;
    width: 25rem;
    height: 3.5rem;
    padding-left: 13px;
    color: #fff;
  }
  
  .login-input::placeholder {
    padding: auto;
    color: #fff;
    font-size: 1.2rem;
  }
  .login-input[type="text"] {
    font-size: 20px;
  }
  
  .sign-in {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(53, 71, 97, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.06);
    border-radius: 50px 0 0 50px;
    margin: 0;
  }
  
  #login-button {
    background: linear-gradient(
      180deg,
      rgba(0, 255, 37, 0.5) 0%,
      #000000 99.99%,
      rgba(54, 165, 70, 0.5) 100%
    );
    border: 0px solid rgba(140, 255, 0, 0.15);
    border-radius: 20px;
    margin: 5%;
    margin-left: 2rem;
    width: 25rem;
    height: 3.5rem;
    padding-left: 13px;
    color: white;
    font-size: 20px;
    font: Mulish;
  }
  
  .moptros {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    margin-top: 0.6rem;
    position: absolute;
    top: 67%;
  }
  
  .vehicle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 300;
  }
  
  form > a {
    margin-right: 2rem;
    padding: 0;
    text-decoration: none;
  }
  
  h5 {
    padding-top: 5px;
  }
  /* .fp {
      display: flex;
      padding-right: 17rem;
  } */
  button:hover {
    cursor: pointer;
  }
  
  #warning {
    margin-right: 20%;
    width: 40%;
    background: none;
    border: none;
  }
  
  #warning::placeholder {
    color: red;
  }
  .data {
    color: white;
  }
  
  #main-alert-box {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 98vh;
    right: 0;
    background: rgb(0, 0, 0, 0.4);
    z-index: 15;
  }
  
  .loader {
    position: absolute;
    display: block;
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    color: #fff;
  }
  .loader:before,
  .loader:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
  }
  .loader:after {
  color: #44d1c8;
  transform: rotateY(70deg);
  animation-delay: .4s;
  }
  
  @keyframes rotate {
  0% {
  transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
  transform: translate(-50%, -50%) rotateZ(360deg);
  }
  }
  
  @keyframes rotateccw {
  0% {
  transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
  transform: translate(-50%, -50%) rotate(-360deg);
  }
  }
  
  @keyframes spin {
  0%,
  100% {
  box-shadow: .2em 0px 0 0px currentcolor;
  }
  12% {
  box-shadow: .2em .2em 0 0 currentcolor;
  }
  25% {
  box-shadow: 0 .2em 0 0px currentcolor;
  }
  37% {
  box-shadow: -.2em .2em 0 0 currentcolor;
  }
  50% {
  box-shadow: -.2em 0 0 0 currentcolor;
  }
  62% {
  box-shadow: -.2em -.2em 0 0 currentcolor;
  }
  75% {
  box-shadow: 0px -.2em 0 0 currentcolor;
  }
  87% {
  box-shadow: .2em -.2em 0 0 currentcolor;
  }
  }