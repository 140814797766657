.sidenav-main {
  position: absolute;
  inset: 0;
  width: 8%;
  background-color: rgba(0, 0, 0, 0.6);
  padding-top: 5%;
}
.sidebar {
  color: white;
  padding: 30px 0 0;
  width: 11.2%;
  justify-content: center;
  flex: 1 1;
  z-index: 10;
  overflow: hidden;
}

.list {
  padding: 0;
  padding-top: 4rem;
  top: 25px;
  bottom: 25px;
  text-align: center;
}

.a:hover {
  transition: none;
  opacity: 1;
  background-color: #2e2441;
  border-left: 8px solid #f7931d;
  border-right: 2px solid #fff;
  cursor: pointer;
}

.a {
  list-style: none;
  backdrop-filter: blur(54px);
  border-radius: 6px 0px 0px 6px;
}
.button {
  background: none;
}
.main-home {
  background-color: #2e2441;
  border-left: 8px solid #f7931d;
  opacity: 1;

  list-style: none;
  backdrop-filter: blur(54px);
  border-radius: 6px 0px 0px 6px;
}

#list_item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 4%;
}

.a {
  opacity: 0.5;
}
h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;

  color: #ffffff;
}
a:link {
  text-decoration: none;
}

@media only screen and (min-width: 375px) and (max-width: 435px) {
  .sidenav-main {
    width: 13%;
    top: 5%;
  }

  .sidenav-main img {
    display: inline; /* Show the icon */
  }

  .sidenav-main h3 {
    display: none; /* Hide the name */
  }

  @media only screen and (min-width: 430px) {
    .sidenav-main {
      top: 5.5%;
    }
  }
}
