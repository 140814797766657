.dash-body {
  position: absolute;
  inset: 0;
  top: 8%;
  left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: 0;
}
.main-content {
  width: 90%;
  height: 85%;
  border-radius: 15px;
}
.app-lists {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #15202f;
  box-sizing: border-box;
  padding: 5%;
  border-radius: 15px;
}
.dash-box {
  box-sizing: border-box;
  padding: 2%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
}

.web-app {
  width: 93%;
  display: grid;
  padding-top: 4%;
  grid-template-columns: repeat(3, 1fr);
  gap: 2%;
  row-gap: 3rem;
}
.web-app > * {
  padding-top: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* row-gap: 20%; */
  /* height: 100%; */
  padding-left: 3%;
  row-gap: 1rem;
}
.mob-app {
  width: 100%;
  gap: 5%;
}

.apps {
  padding: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* row-gap: 20%; */
  /* height: 100%; */
  padding-left: 3%;
  row-gap: 1rem;
  transition: box-shadow 0.3s ease;
  border-radius: 10px;
  text-align: center;
}

.apps div {
  /* background-color: #f0f0f0;  */
  border-radius: 8px; /* Adjust the border-radius as needed */
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.2); /* Soft shadow effect */
  transition: opacity 0.3s ease;
}

.apps:hover {
  box-shadow: 5px 5px 30px rgba(138, 202, 195, 0.2);
}

.app-name:hover div {
  opacity: 1;
}

.app-name {
  padding-top: 2;
  opacity: 0.8;
  cursor: pointer;
  font-size: 0.8rem;
}

.app-name:hover {
  opacity: 1;
}
.apps:hover {
  cursor: pointer;
}

@media only screen and (min-width: 375px) and (max-width: 500px) {
  .dash-body {
    /* background: beige; */
    top: 2%;
  }
  .dash-box {
    box-sizing: border-box;
    padding: 2%;
    display: grid;
    grid-template-columns: auto;
    justify-items: center;
    grid-gap: 20px;
  }
  .app-lists,
  .main-content {
    width: 100%;
  }
  .main-content {
    height: 87%;
  }
  @media only screen and (min-width: 430px) {
    .dash-box {
      height: 78%;
      padding-left: 2rem;
    }
  }
}
