@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.head {
    position: absolute;
    inset: 0;
    display: flex;
    height: 8%;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    font-family: "Montserrat";
}
.w2h-image {
    position: absolute;
    left: 0;
    height: 50%;
    padding-left: 1%;
    z-index: 5;
}

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;1,400&display=swap');

.title{
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  color: white;
  background: #15202F;
  justify-content: center;
  width: 100%;
  top: 0;
  /* margin: 0; */
  /* padding-left: 42%; */
  font-family: 'Lato';
  position: fixed;
  z-index: 50;
  height: 3rem;
  background: rgba(0, 0, 0, 0.4);
box-shadow: inset 0px 4px 0px rgba(0, 0, 0, 0.25);
backdrop-filter: blur(1px);
  font-family: 'Montserrat', sans-serif;
  }
  .head-title {
    font-family: 'Montserrat', sans-serif;
  }
  .icons {
    position: absolute;
    display: flex;
    justify-content: center;
    right: 4rem;
    gap: 3rem;
    align-items: center;
  }


.profile {
  padding-left: 0;
  flex: 1;
  float: right;
  opacity: 0.8;
}

.home-setting:hover {
  opacity: 1;
  cursor: pointer;
}
.logo {
    padding-right: 0.5%;
}
.home-app {
  padding: 10px;
}

.home-app-open {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 10px;
}

.home-app:hover {
  opacity: 1;
  cursor: pointer;
}
/* .home-app-open:hover {
  opacity: 1;
  cursor: pointer;
} */
.profile:hover {
  opacity: 1;
  cursor: pointer;
}
.text {
  text-align: center;
}

.dropdown-menu {
  transition: width;
  position: absolute;
  height: 17rem;
  width: 15rem;
  top: 3rem;
  right: 0rem;
  background: #142329;
  opacity: 1;
  border-radius: 0px 0px 4px 4px;
  z-index: 15;
  padding: 10px;
  display: flex;
  flex-direction: column;
}


.dropdown-items {
  font-family: 'Mulish', sans-serif;
  margin: 0.7rem;
}
.app-dropdown {
  position: absolute;
  height: 87vh;
  width: 87rem;
  position: absolute;
  right: -1rem;
  display: flex;
  top: 3.9rem;
  background: #141E2C;
  border-radius: 20px;
}

.main-data {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 4rem;
  padding-top: 4rem;
  position: absolute;
    top: inherit;
    left: inherit;
}
.apps-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-img:hover {
  cursor: pointer;
}

.drop {
  position: absolute;
  /* top: 146%; */
  top: 113%;
  width: 9rem;
  background: rgb(0, 0, 0, 0.8);
}
.drop * {
  padding: 7%;
  cursor: pointer;
  opacity: 0.7;
}
.drop *:hover {
  opacity: 1;
}