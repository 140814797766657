.foot {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  color: white;
  /* opacity: 0.47; */
  overflow: hidden;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-text {
  opacity: 0.8;
}

@media only screen and (min-width: 375px) and (max-width: 500px) {
  .footer-text {
   text-align: center;
   font-size: 12px;
  }
}
