@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css?family=Muli');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: medium;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  margin:0;
  padding: 0;
  background-image: url("./assets/bg_lines.png");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  background-image: linear-gradient(to top right, rgba(99,53,100,1) 0%, rgba(0,0,0,1) 47%, rgba(36,108,115,1) 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;  
}

#copyrights
{
  font-family: 'Muli';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.07em;
  color: #FFFFFF;
  opacity: 0.8;
  position: fixed; 
  bottom: 0;
  width: 100%;
  /* Height of the footer*/
  
}

