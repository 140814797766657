.download-popup {
    position: absolute;
    inset: 0;
    top: -9%;
    background-color: rgba(0,0,0,0.8);
    left: -6%;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.download-close {
    position: absolute;
    right: 5%;
    top: 5%;
}

.close-button {
    height: 2.5rem;
    width: 5rem;
    background-color: red;
    color: white;
    border-radius: 5px;
    opacity: 0.8;
}
.close-button:hover {
    opacity: 1;
}